import React, { Component } from 'react'
import he from 'he'
import { graphql, Link } from 'gatsby'

import ContentImageWithVideo from '../components/content-image-with-video'
import Sectors from '../components/sectors'

import RenderBlock from '../utils/render-block'

import SEO from '../components/seo'

class SectorTemplate extends Component {
  render() {
    let sector = this.props.data.sector
    let metaDescription = sector.yoast_meta.find(x => x.name === 'description') ? sector.yoast_meta.find(x => x.name === 'description').content : '';
    return (
      <>
        <SEO title={he.decode(sector.yoast_title)} bodyClass={sector.slug} description={metaDescription} />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              { sector.parent_element?.title && <li><Link to={`/sector/${sector.parent_element?.slug}/`}>{sector.parent_element?.title}</Link></li>}
              <li>{he.decode(sector.title)}</li>
            </ul>
          </div>
        </section>
        <ContentImageWithVideo {...sector} />
        { sector.acf.post_blocks_sector && sector.acf.post_blocks_sector.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
        <div style={{paddingTop: `80px`, paddingBottom: `40px`}}>
          <Sectors />
        </div>
      </>
    )
  }
}


export const sectorQuery = graphql`
  query ($id: String!) {
    sector: wordpressWpSector(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      parent_element {
        title
        slug
      }
      acf {
        subtitle
        content
        button {
          text
          link
        }
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        video
        video_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        video_title
        post_blocks_sector {
          __typename
          ... on WordPressAcf_three_column_content {
            column {
              title
              content
            }
            text_color
            background_color
          }
          ... on WordPressAcf_testimonial {
           testimonial {
             content: post_content
             acf {
               name
               logo {
                localFile {
                  childImageSharp {
                    fixed(width: 1400) {
                      src
                    }
                  }
                }
               }
             }
           }
          }
          ... on WordPressAcf_quick_contact {
            left_image {
              localFile {
                childImageSharp {
                  fixed(width: 1400) {
                    src
                  }
                }
              }
            }
            right_title
            right_content
            left_title
            left_content
            right_image {
              localFile {
                childImageSharp {
                  fixed(width: 1400) {
                    src
                  }
                }
              }
            }
            form_title
            download_title
            download {
              source_url
            }
          }
        }
      }
    }
    sectors: allWordpressWpSector(limit: 3) {
      edges {
        node {
          slug
          title
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SectorTemplate